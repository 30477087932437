<template>
  <div style="height: 100%">
    <a-card
      style="width:100%;height: 100%"
      title="مركز فعاليات وأنشطة خريطة"
      :tab-list="tabList"
      :active-tab-key="key"
      @tabChange="key => onTabChange(key, 'key')"
    >
      <a-button slot="extra" @click="logout" >تسجيل الخروج</a-button>
      <span slot="customRender" slot-scope="item"> <a-icon :type="item.key" />{{ item.value }} </span>
      
      <p v-if="key === 'instagram'">
        <a-row type="flex" justify="space-around">
          <a-row>
            <ElfSightWidget id="elfsight-app-a7cbb955-0ea4-4479-b7b9-06298ee3387e"/>
          </a-row>
          <a-row>
            <ElfSightWidget id="elfsight-app-e2a3f82d-33e6-46e4-9179-700170f397bd"/>
          </a-row>
          <a-row>
            <ElfSightWidget id="elfsight-app-4731cdc5-cb51-4630-a6bc-30e23972f7aa"/>
          </a-row>
          <a-row>
            <ElfSightWidget id="elfsight-app-1f4d570b-5b2d-4d78-9315-8bfc482dfc5f"/>
          </a-row>
          <a-row>
            <ElfSightWidget id="elfsight-app-4c92072d-3a66-40d9-80df-c42e21939b8c"/>
          </a-row>
        </a-row>
      </p>
      <p v-else-if="key === 'twitter'">
        Twitter Content :TODO
      </p>
      <p v-else-if="key === 'my events'">
        <my-events></my-events>
      </p>
    </a-card>
    <CreateEvent/>
  </div>
</template>

<script>
import ElfSightWidget from '@/components/widgets/ElfSight.vue'
import CreateEvent from '@/components/events/Create.vue'
import MyEvents from '@/components/events/List.vue'
import auth from '@/auth/index'

export default {
  name: 'Home',
  components: {
    ElfSightWidget,
    CreateEvent,
    MyEvents
  },
  data() {
    return {
      tabList: [
        {
          key: 'instagram',
          value: 'إنستغرام',
          scopedSlots: { tab: 'customRender' },
        },
        {
          key: 'twitter',
          value: 'تويتر',
          scopedSlots: { tab: 'customRender' },
        },
        {
          key: 'my events',
          value: 'فعالياتي',
          scopedSlots: { tab: 'customRender' },
        },
      ],
      key: 'instagram',
    };
  },
  mounted() {
    const plugin = document.createElement("script")
    plugin.setAttribute(
      "src",
      "https://apps.elfsight.com/p/platform.js"
    )
    plugin.defer = true
    document.head.appendChild(plugin)
  },
  methods: {
    onTabChange(key, type) {
      this[type] = key;
    },
    logout() {
      auth.logout()
      this.$router.push('/auth/login').catch(() => {})
    }
  },
};
</script>

<style>
#app {
  color: #2c3e50;
}
.float{
	position: fixed !important;
	bottom: 80px;
	left:  auto;
	right:  100px;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.above-container, 
  .float, .ant-select-dropdown,
  .ant-calendar-picker-container,
  .pac-container.pac-logo.hdpi, .ant-modal-mask {
    z-index: 1000000 !important;
}
.ant-notification.ant-notification-topLeft{
  z-index: 10000000;
}

</style>
