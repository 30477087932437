<template>
  <div>
    <a-row :gutter="16"  class="my-events__search-filter">
      <a-form-model layout="inline" :model="form" @submit="search" @submit.native.prevent>
        <a-form-model-item>
          <a-input v-model="form.q" placeholder="ابحث في عنوان أو وصف الفعالية" style="width: 300px">
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="status" ref="status" label="حالة الفعالية">
          <a-select v-model="form.status" placeholder="اختر حالة الفعالية لفترة القائمة" style="width: 300px">
            <a-select-option value="">كل الحالات</a-select-option>
            <a-select-option :value="1">مسودة</a-select-option>
            <a-select-option :value="3">منشورة</a-select-option>
            <a-select-option :value="4">منتهية</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            html-type="submit">
            بحث
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-row>
    <a-spin v-if="loading" size="large" />
    <a-row v-else :gutter="16" class="my-events__list">
      <a-col v-if="events.length == 0" class="my-events__not-found" align="center">
        <img src="@/assets/images/no_events.png" alt="لم يتم العثور على أي فعالية">
        <h2>لم يتم العثور على أية فعالية</h2>
      </a-col>
      <a-row  :gutter="16">
        <a-col :md="{ span: 6 }" :sm="{ span: 12 }" :xs="{ span: 24 }" v-for="event in events" :key="event.id">
          <a-card hoverable>
            <img v-if="event.picture"
              slot="cover"
              alt="صورة الفعالية"
              :src="event.pictures[0].base_url + '/' + event.pictures[0].path"/>
            <template slot="actions" class="ant-card-actions">
              <a-icon key="edit" type="edit" @click="editEvent(event)"/>
              <a-badge v-if="event.status == 1" count="مسودة" :number-style="{ backgroundColor: '#4E4578' }" />
              <a-badge v-if="event.status == 3" count="منشورة" :number-style="{ backgroundColor: '#49bbb1' }" />
              <a-badge v-if="event.status == 4" count="منتهية" :number-style="{ backgroundColor: '#E14686' }" />
              <a :href="'https://kharitah.sa/events/view/' + event.id" target="_blank"><a-icon key="link" type="link"/></a>
            </template>
            <a-card-meta :title="event.name || 'بدون عنوان'" :description="'رقم الفعالية : ' + event.id">
              <a-avatar slot="avatar" :src="event.icon_path"/>
            </a-card-meta>
          </a-card>
        </a-col>
        <a-pagination 
          v-model="page"
          show-less-items
          :total="total"
          :page-size="12"
          @change="onChange" />
      </a-row>
      <update-event ref="updateEvent"></update-event>
    </a-row>
  </div>
</template>

<script>
import UpdateEvent from '@/components/events/Update.vue'

export default {
  name: 'my-events',
  components: {
    UpdateEvent
  },
  data() {
    return {
      form: {
        q: '',
        status: ''
      },
      events: [],
      total: 0,
      loading: false,
      event_id: null,
      page: 1
    }
  },
  created() {
    this.loading = true;
     this.$store.dispatch('events/fetchEvents', { page : this.page })
     .then(response => {
       this.loading = false;
       this.events = response.data.items
       this.total = response.data._meta.totalCount
     })
  },
  methods: {
    editEvent(event) {
      this.$refs.updateEvent.showForm(event.id)
    },
    onChange(current) {
      this.loading = true;
      this.$store.dispatch('events/fetchEvents', { page : current })
      .then(response => {
        this.loading = false;
        this.events = response.data.items
        this.total = response.data._meta.totalCount
      })
    },
    search() {
      this.loading = true;
      this.$store.dispatch('events/fetchEvents', this.form)
      .then(response => {
        this.loading = false;
        this.events = response.data.items
        this.total = response.data._meta.totalCount
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.my-events__not-found {
  img {
    width: 400px
  }
}
.my-events__search-filter {
  margin-bottom: 20px;
}
.ant-col {
  margin-bottom: 20px;
}
</style>
