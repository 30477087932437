<template>
    <div>
        <a-popover title="إضافة فعالية">
            <template slot="content">
                <p>إضغط هنا لإضافة فعالية</p>
            </template>
            <a-button type="primary" class="float" size="large" shape="round" @click="visible=true"> <a-icon type="plus" /> أضف فعالية </a-button>
        </a-popover>
        <a-drawer
        title="إضافة فعالية جديدة"
        width="85%"
        placement='left'
        :visible="visible"
        class="above-container"
        :body-style="{ paddingBottom: '80px' }"
        @close="visible=false"
        >
            <a-form-model ref="addEvent" :model="form" :rules="rules" layout="vertical">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-row :gutter="16">
                            <a-col :md="{ span: 12, offset: 12 }">
                                <a-form-model-item label="مكان الفعالية" ref="event_place" prop="event_place">
                                    <a-radio-group v-model="form.event_place">
                                        <a-radio value="online">
                                        أونلاين
                                        </a-radio>
                                        <a-radio value="onsite">
                                        في مكان
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16" v-if="form.event_place == 'online'">
                            <a-col :span="12">
                                <a-form-model-item label="عدد المقاعد" ref="seats_number" prop="seats_number">
                                <a-input
                                    v-model="form.seats_number"
                                    placeholder="الرجاء إدخال عدد المقاعد المتاحة"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="ثمن المقعد" ref="seats_price" prop="seats_price">
                                <a-input
                                    v-model="form.seats_price"
                                    placeholder="الرجاء إدخال ثمن المقعد"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16" v-if="form.event_place == 'online'">
                            <a-col :span="12">
                                <a-form-model-item label="طريقة الحجز" ref="seats_place" prop="seats_place">
                                <a-input
                                    v-model="form.seats_place"
                                    placeholder="الرجاء إدخال طريقة الحجز"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="رابط حجز المقاعد" ref="seats_link" prop="seats_link">
                                <a-input
                                    v-model="form.seats_link"
                                    placeholder="الرجاء إدخال رابط حجز المقاعد"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16" v-if="form.event_place == 'onsite'">
                            <a-col :span="12">
                                <a-form-model-item label="مكان شراء التذاكر" ref="tickets_places" prop="tickets_places">
                                <a-input
                                    v-model="form.tickets_places"
                                    placeholder="الرجاء إدخال مكان شراء التذاكر"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="مكان رابط التذاكر" ref="tickets_link" prop="tickets_link">
                                <a-input
                                    v-model="form.tickets_link"
                                    placeholder="الرجاء إدخال مكان رابط التذاكر"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16" v-if="form.event_place == 'onsite'">
                            <a-col :md="{ span:12, offset: 12 }">
                                <a-form-model-item label="ثمن التذكرة" ref="tickets_price" prop="tickets_price">
                                <a-input
                                    v-model="form.tickets_price"
                                    placeholder="الرجاء إدخال ثمن التذكرة"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item label="رابط الفيديو" ref="video_url" prop="video_url">
                                <a-input
                                    v-model="form.video_url"
                                    placeholder="الرجاء إدخال رابط الفيديو الخاص بالفعالية"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="تفعيل عرض التعليقات" ref="comments_active" prop="comments_active">
                                    <a-radio-group v-model="form.comments_active">
                                        <a-radio value="1">
                                        نعم
                                        </a-radio>
                                        <a-radio value="0">
                                        لا
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <a-form-model-item label="صور الفعالية" ref="images" prop="images">
                                <a-upload
                                    name="file"
                                    :action="`${this.$store.state.base_url}/api/v1/events/upload-image`"
                                    list-type="picture-card"
                                    :file-list="imagesPreviewList"
                                    :headers="{
                                        'Authorization': `Bearer ${this.$store.state.auth.access_token}`
                                    }"
                                    @preview="handlePreview"
                                    @change="handleChange">
                                    <div v-if="imagesPreviewList.length < 8">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">
                                        رفع
                                        </div>
                                    </div>
                                </a-upload>
                                <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
                                    <img alt="example" style="width: 100%" :src="previewImage" />
                                </a-modal>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <a-form-model-item label="موقع الفعالية" ref="location_lat" prop="location_lat">
                                    <label layout="inline" :style="{ marginBottom: '8px' }">
                                        أدخل مكان للبحث في الخريطة
                                        <GmapAutocomplete
                                        class="ant-input"
                                        style="width:50%; position: inline; margin-bottom:10px"
                                        @place_changed="setPlace">
                                        </GmapAutocomplete>
                                    </label>
                                    <GmapMap
                                    :center="{lat:form.location_lat, lng:form.location_lng}"
                                    :zoom="10"
                                    map-type-id="terrain"
                                    style="width: 100%; height: 300px">
                                    <GmapMarker
                                        :position="{lat:form.location_lat, lng:form.location_lng}"
                                        :clickable="true"
                                        :draggable="true"
                                        @drag="updateCoordinates"/>
                                    </GmapMap>
                                </a-form-model-item>
                                <a-form-model-item label="فترات الفعالية" ref="EventPeriod" prop="EventPeriod">
                                        <span  v-for="(period, index) in form.EventPeriod" v-bind:key="index">
                                            <a-range-picker
                                            style="margin-bottom:8px"
                                            :locale="locale"
                                            :disabled-date="disabledDate"
                                            v-model="period.date_range"
                                            :show-time="{
                                                hideDisabledOptions: true,
                                                defaultValue: [moment('00:00', 'HH:mm').toDate(), moment('11:59', 'HH:mm').toDate()],
                                            }"
                                            format="YYYY/MM/DD - hh:mm A"
                                            separator=" إلى "
                                            @change="setDatePicker(index)"/>
                                            <a-button v-if="form.EventPeriod.length > 1"
                                            type="danger" 
                                            icon="delete" 
                                            :style="{ marginLeft: '8px' }"
                                            @click="form.EventPeriod.splice(index, 1)" />
                                        </span>
                                        <a-button 
                                        type="primary" 
                                        icon="plus" 
                                        :style="{ marginLeft: '8px' }"
                                        @click="form.EventPeriod.push({
                                            date_range: '',
                                            start_date: '',
                                            end_date: '',
                                            time_from: '',
                                            time_to: ''
                                        })" />
                                </a-form-model-item>        
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="12">
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item ref="city_id" label="المدينة" prop="city_id">
                                <a-select
                                    v-model="form.city_id"
                                    placeholder="الرجاء اختيار مدينة الفعالية">
                                    <a-select-option v-for="city in cities" :value="city.id" v-bind:key="city.id">
                                    {{ city.name}}
                                    </a-select-option>
                                </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item ref="name" label="العنوان" prop="name">
                                <a-input
                                    v-model="form.name"
                                    placeholder="الرجاء إدخال عنوان الفعالية"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item ref="event_url" label="رابط الفعالية" prop="event_url">
                                <a-input
                                    v-model="form.event_url"
                                    placeholder="الرجاء إدخال رابط الفعالية"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item ref="category_id" label="تصنيف الفعالية" prop="category_id">
                                <a-select
                                    v-model="form.category_id"
                                    placeholder="الرجاء إدخال تصنيف الفعالية">
                                    <a-select-option v-for="category in categories" :value="category.id" v-bind:key="category.id">
                                    {{ category.name}}
                                    </a-select-option>
                                </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12" v-if="form.event_type == '1'">
                                <a-form-model-item ref="commercial_number" label="رقم السجل التجاري" prop="commercial_number">
                                <a-input
                                    v-model="form.commercial_number"
                                    placeholder="الرجاء إدخال رقم السجل التجاري"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12" v-if="form.event_type == '2'">
                                <a-form-model-item ref="lisence_number" label="رقم الترخيص" prop="lisence_number">
                                <a-input
                                    v-model="form.lisence_number"
                                    placeholder="الرجاء إدخال رقم الترخيص"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item ref="event_type" label="نوع الفعالية" prop="event_type">
                                    <a-radio-group v-model="form.event_type">
                                        <a-radio value="1">
                                        تجارية
                                        </a-radio>
                                        <a-radio value="2">
                                        ترفيهية
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <a-form-model-item ref="about" label="وصف الفعالية" prop="about">
                                    <quill-editor ref="editor"
                                        class="editor"
                                        v-model="form.about"
                                        :options="{
                                            theme: 'snow',
                                            modules: {
                                                toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                [{ 'color': [] }, { 'background': [] }],
                                                [{ 'font': [] }],
                                                [{ 'align': [] }],
                                                ['link'],
                                                ['clean']
                                                ]
                                            }
                                        }">
                                    </quill-editor>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item ref="contact_site" label="الموقع" prop="contact_site">
                                <a-input
                                    v-model="form.contact_site"
                                    placeholder="الرجاء إدخال الموقع الالكتروني الخاص بالفعالية"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item ref="organiser" label="الجهة المنظمة" prop="organiser">
                                <a-input
                                    v-model="form.organiser"
                                    placeholder="الرجاء إدخال اسم الجهة المنظمة"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item label="رقم الهاتف" ref="contact_phone" prop="contact_phone">
                                <a-input
                                    v-model="form.contact_phone"
                                    placeholder="الرجاء إدخال رقم هاتف التواصل للجهة المنظمة"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="رقم الواتساب" ref="contact_whatsapp" prop="contact_whatsapp">
                                <a-input
                                    v-model="form.contact_whatsapp"
                                    placeholder="الرجاء إدخال رقم الواتساب للجهة المنظمة"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item label="تويتر" ref="contact_twitter" prop="contact_twitter">
                                <a-input
                                    v-model="form.contact_twitter"
                                    placeholder="الرجاء إدخال حساب تويتر للجهة المنظمة"
                                />
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="انستغرام" ref="contact_instagram" prop="contact_instagram">
                                <a-input
                                    v-model="form.contact_instagram"
                                    placeholder="الرجاء إدخال حساب انستغرام للجهة المنظمة"
                                />
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item label="سنابشات" ref="contact_snapshat" prop="contact_snapshat">
                                <a-input
                                    v-model="form.contact_snapshat"
                                    placeholder="الرجاء إدخال حساب السنابشات للجهة المنظمة"
                                />
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="الإيميل" ref="contact_email" prop="contact_email">
                                <a-input
                                    v-model="form.contact_email"
                                    placeholder="الرجاء إدخال إيميل الجهة المنظمة"
                                />
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item label="معاينة">
                                    <img :src="form.icon_path" style="border-radius: 50%;">
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="أيقونة الفعالية" ref="icon_path" prop="icon_path">
                                    <vue-croppie ref="croppie" 
                                        :boundary="{ width: 150, height: 150 }" :viewport="{ width:150, height:150, type: 'circle' }">
                                    </vue-croppie>
                                    <a-row>
                                        <a-col :span="12">
                                            <a-button v-if="showCrop" type="primary" icon="scissor" @click="crop">قص الصورة</a-button>
                                        </a-col>
                                        <a-col :span="12">
                                            <a-upload :file-list="[]" :before-upload="croppie">
                                                <a-button> <a-icon type="upload" /> رفع الصورة </a-button>
                                            </a-upload>
                                        </a-col>
                                    </a-row>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-form-model>
            <div
                :style="{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
                }"
            >
                <a-button :style="{ marginLeft: '8px' }" @click="visible=false">
                تراجع
                </a-button>
                <a-button type="primary" :loading="loading" @click="submit()">
                اعتماد
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import axios from '@/axios';
import moment from 'moment';
import { quillEditor } from 'vue-quill-editor'
import locale from 'ant-design-vue/es/date-picker/locale/ar_EG';

export default {
    name: 'CreateEvent',
    components: {
        quillEditor
    },
    data() {
        let validatePeriods = (rule, value, callback) => {
            if (this.form.EventPeriod.filter(period => period.date_range == '').length > 0)
                callback(new Error('الرجاء إدخال فترات الفعالية'))
            callback()
        };
        return {
            locale,
            default_form: {},
            form: {
                name: '',
                user_id: '',
                city_id: '',
                category_id: '',
                event_type: '1',
                commercial_number: '',
                lisence_number: '',
                about: '',
                organiser: '',
                event_url: '',
                status: 1,
                comments_active: '1',
                video_url: '',
                contact_phone: '',
                contact_whatsapp: '',
                contact_twitter: '',
                contact_instagram: '',
                contact_snapshat: '',
                contact_email: '',
                contact_site: '',
                event_place: 'online',
                seats_number: '',
                seats_price: '',
                seats_place: '',
                seats_link: '',
                tickets_places: '',
                tickets_link: '',
                tickets_price: '',
                images: [],
                location_lng: 50.094460,
                location_lat: 26.439280,
                icon_path: '',
                EventPeriod: [{date_range: '', start_date: '', end_date: '', time_from: '', time_to: ''}]
            },
            visible: false,
            cities: [],
            categories: [],
            previewVisible: false,
            imagesPreviewList: [],
            previewImage: '',
            showCrop: false,
            loading: false,
            rules: {
                name: [{ required: true, message: 'الرجاء إدخال اسم الفعالية', trigger: 'blur' }],
                city_id: [{ required: true, message: 'الرجاء إدخال مدينة الفعالية', trigger: 'blur' }],
                category: [{ required: true, message: 'الرجاء إدخال تصنيف الفعالية', trigger: 'blur' }],
                event_url: [{ required: true, message: 'الرجاء إدخال رابط الفعالية', trigger: 'blur' }],
                commercial_number: [{ required: true, message: 'الرجاء إدخال رقم السجل التجاري', trigger: 'blur' }],
                lisence_number: [{ required: true, message: 'الرجاء إدخال رقم الترخيص', trigger: 'blur' }],
                about: [{ required: true, message: 'الرجاء إدخال وصف الفعالية', trigger: 'blur' }],
                organiser: [{ required: true, message: 'الرجاء إدخال اسم الجهة المنظمة', trigger: 'blur' }],
                location_lat: [{ required: true, message: 'الرجاء إدخال موقع الفعالية', trigger: 'blur' }],
                location_lng: [{ required: true, message: 'الرجاء إدخال موقع الفعالية', trigger: 'blur' }],
                images: [{ type: 'array', required: true, message: 'الرجاء إدخال صورة واحدة على الأقل', trigger: 'blur' }],
                icon_path: [{ required: true, message: 'الرجاء إدخال أيقونة الفعالية', trigger: 'blur' }],
                EventPeriod: [{ validator: validatePeriods, trigger: 'blur' }],
            },
        };
    },
    mounted() {
        this.getCities()
        this.getCategories()
        this.form.user_id = this.$store.state.auth.user.id
        this.default_form =  Object.assign({}, this.form)
    },
    methods: {
        moment,
        getCities() {
            return axios.get("/api/v1/events/cities/")
            .then(response => {
                // JSON responses are automatically parsed.
                this.cities = response.data.items
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
        getCategories() {
            return axios.get("/api/v1/events/categories/")
            .then(response => {
                // JSON responses are automatically parsed.
                this.categories = response.data.items
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await this.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ fileList }) {
            this.imagesPreviewList = fileList;
            this.form.images = fileList.map(image => {
                return {
                    base_url: image.response.img_data.base_url,
                    path: image.response.img_data.path 
                }
            })
        },
        setPlace(place) {
            this.form.location_lat = place.geometry.location.lat()
            this.form.location_lng = place.geometry.location.lng()
        },
        disabledDate(current) {
            // Can not select days before today and today
            return current && current < moment().endOf('day');
        },
        updateCoordinates(location) {
            this.form.location_lat = location.latLng.lat()
            this.form.location_lng = location.latLng.lng()
        },
        setDatePicker(index) {
            let start_date = this.form.EventPeriod[index]['date_range'][0]
            let end_date = this.form.EventPeriod[index]['date_range'][1]
            this.form.EventPeriod[index]['start_date'] = start_date.format('YYYY/MM/DD')
            this.form.EventPeriod[index]['end_date'] = end_date.format('YYYY/MM/DD')
            this.form.EventPeriod[index]['time_from'] = start_date.format('h:mm A')
            this.form.EventPeriod[index]['time_to'] = end_date.format('h:mm A')
        },
        submit() {
            this.$refs.addEvent.validate(valid => {
                if (valid) {
                    this.loading = true;
                    return axios.post("/api/v1/events/new", this.form,  {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.$store.state.auth.access_token}`
                        }
                    })
                    .then(()=> {
                        this.loading = false;
                        this.resetForm()
                        this.$notification['success']({
                            message: 'Success',
                            description: 'تم إضافة الفعالية بنجاح.',
                            placement: 'topLeft'
                        });
                        this.visible = false
                    })
                    .catch(e => {
                        this.loading = false;
                        this.$notification['error']({
                            message: 'خطأ',
                            description: 'حدث خطأ ما ......',
                            placement: 'topLeft'
                        });
                        this.errors.push(e) 
                    })
                } else
                    return false;
            });
        },
        resetForm() {
            this.imagesPreviewList = []
            this.previewImage = ''
            this.form = this.default_form
            this.form.EventPeriod = [{date_range: '', start_date: '', end_date: '', time_from: '', time_to: ''}]
        },
        croppie (file) {

            var reader = new FileReader();
            reader.onload = e => {
                this.$refs.croppie.bind({
                    url: e.target.result
                }).then(() => this.crop());
                this.showCrop = true
            };

            reader.readAsDataURL(file);
        },
        crop() {
            // Options can be updated.
            // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
            let options = {
                type: 'base64',
                size: { width: 150, height: 150 },
                format: 'jpeg',
                circle: true
            };
            this.$refs.croppie.result(options, output => {
                this.form.icon_path  = output;
            });
        }
    }
}
</script>

<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-col .ant-radio-group {
    height: 30px;
}

.ant-drawer-content-wrapper {
    left:0px;
}
.float {
    right: auto !important;
    left: 100px !important;
}

.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4E4578;
    border-bottom: solid 4px #4E4578;
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    height: 0px;
}
</style>