<template>
  <div class="space-align-container">
        <a-row style="margin-top: 110px;margin-bottom: 110px">
          <a-col :md="{ span: 10, offset: 2 }" :sm="{ span: 0 }" :xs="{ span: 0 }">
            <img src="@/assets/images/auth/login.png" alt="login" class="login-card-img">
          </a-col>
          <a-col :md="{ span: 8, offset: 2 }" :sm="{ span: 24 }" :xs="{ span: 24 }">
            <a-card class="card login-card" title="مركز فعاليات وأنشطة خريطة">
              <div class="card-body">
                <div class="brand-wrapper">
                  <img src="@/assets/images/logo.png" alt="logo" class="logo">
                </div>
                <p class="login-card-description">قم بتسجيل الدخول لحسابك</p>
                <a-form-model ref="login" :model="form" :rules="rules" >
                  <a-form-model-item ref="username" prop="username" label="الإيميل">
                    <a-input v-model="form.username" type="username"/>
                    <a-icon slot="prefix" type="email" style="color: rgba(0,0,0,.25)" />
                  </a-form-model-item>
                  <a-form-model-item ref="password" prop="password" label="كلمة السر" class="form-group mb-4">
                    <a-input-password allow-clear v-model="form.password" type="password"/>
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                  </a-form-model-item>
                  <!-- Remember Me -->
                  <a-form-model-item ref="remember" prop="remember"  class="form-group">
                    <a-checkbox v-model="form.remember">
                      تذكرني
                    </a-checkbox>
                  </a-form-model-item>
                  <a-button type="primary" :block="true" :loading="loading" @click="submit" class="mb-4">
                    تسجيل الدخول
                  </a-button>
                </a-form-model>
                <br>
                <nav class="login-card-footer-nav">
                </nav>
              </div>
            </a-card>
          </a-col>
        </a-row>
  </div>
</template>

<style lang="scss" scoped>
body {
    font-family: "Karla", sans-serif;
    background-color: #d0d0ce;
    min-height: 100vh;
}
.space-align-container {
  display: flex;
  align-content: center;
  align-items: center;
  height: 100%;
  .ant-row{
    width: 100%;
  }
}

.brand-wrapper {
    text-align: right;
    .logo {
        height: 60px;
    }
}

.login-card {
    border: 0;
    border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
    overflow: hidden;

    &-img {
        border-radius: 0;
        // position: absolute;
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }
    .card-body {
        padding: 0px 20px;
        text-align: start;

        @media (max-width: 422px) {
            padding: 0px 24px;
        }
    }

    &-description {
        font-size: 25px;
        color: #000;
        font-weight: normal;
        margin-bottom: 23px;
    }

    .forgot-password-link {
        font-size: 14px;
        color: #919aa3;
        float: right;
        text-align: right;
    }

    &-footer-text {
        font-size: 16px;
        color: #0d2366;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            margin-bottom: 24px;
        }
    }

    &-footer-nav {
        a {
            font-size: 14px;
            color: #919aa3;
        }
    }
}
</style>

<script>

export default {
  data: () => ({
    form: {
      username: '',
      password: '',
      remember: false
    },
    loading: false,
    rules: {
      username: [
        {  type: 'email', message: 'الرجاء إدخال عنوان إيميل صحيح', trigger: 'blur'},
        {  required: true, message: 'الرجاء إدخال الإيميل', trigger: 'blur' }
      ],
      password: [
        { required: true, message: 'الرجاء إدخال كلمة السر', trigger: 'blur' }
      ]
    },
  }),
  created() {
    if (this.alreadyLoggedIn()) {
      this.$router.push(this.$router.currentRoute.query.redirect || '/').catch(() => {})
    }
  },
  methods: {
    alreadyLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    async submit () {
      this.$refs.login.validate(valid => {
        if (!valid)
          return false;

        this.loading = true;
        const payload = {
          remember_me: this.form.remember,
          username: this.form.username,
          password: this.form.password
        }

        this.$store.dispatch('auth/login', payload)
        .then(() => { 
          this.loading = false 
          this.$notification['info']({
            message: 'مرحباً',
            description: `أهلا وسهلا ${this.$store.state.auth.user.name} , مرحباً بك مجدداً`,
            placement: 'topLeft',
          });
        })
        .catch(error => {
          this.loading = false
          this.$notification['error']({
            message: 'خطأ في تسجيل الدخول',
            description: error[0] ? error[0]: 'حدث خطأ ما ....',
            placement: 'topLeft',
          });
        })
      });
    }
  }
}
</script>